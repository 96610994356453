import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import NoMatch from './components/404/NoMatch';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Home from './components/Home/Home';
import Landing from './components/Projects/Landing/Landing';
import Project from './components/Projects/Specific/Project';
import Logistics from './components/Services/Logistics/Logistics';
import Online from './components/Services/Online/Online';
import Qualitative from './components/Services/Qualitative/Qualitative';
import Quantitative from './components/Services/Quantitative/Quantitative';
import Footer from './components/Shared/Footer';
import NavBar from './components/Shared/NavBar';
import en from './languages/english.json';
import fr from './languages/french.json';

class App extends Component {
	state = {
		lang: 'fr'
	};

	changeLang = lang => {
		this.setState({ lang });
	};

	changeLangRoute = lang => {
		if (lang === this.state.lang) return;
		this.setState({ lang });
	};
	getTypeRoute = (lang, type) => {
		switch (type) {
			case 1:
				return lang === 'fr'
					? fr.routes.quantitative
					: en.routes.quantitative;
			case 2:
				return lang === 'fr'
					? fr.routes.qualitative
					: en.routes.qualitative;
			case 3:
				return lang === 'fr' ? fr.routes.online : en.routes.online;
			case 4:
				return lang === 'fr' ? fr.routes.logistics : en.routes.logistics;

			default:
				break;
		}
	};
	render() {
		const projectsFR = fr.projects.projects.map(p => (
			<Route
				key={p.route}
				path={`/fr/${fr.routes.projects}/${this.getTypeRoute(
					'fr',
					p.type
				)}/${p.route}`}
				render={props => <Project {...props} project={p} />}
			></Route>
		));
		const projectsEN = en.projects.projects.map(p => (
			<Route
				key={p.route}
				path={`/en/${en.routes.projects}/${this.getTypeRoute(
					'en',
					p.type
				)}/${p.route}`}
				render={props => <Project {...props} project={p} />}
			></Route>
		));

		return (
			<BrowserRouter>
				<div className="App">
					<NavBar
						changeLang={this.changeLang}
						changeLangRoute={this.changeLangRoute}
						lang={this.state.lang}
					></NavBar>
					<Switch>
						<Route
							exact
							path="/"
							render={() => <Redirect to={'/' + this.state.lang} />}
						></Route>
						<Route exact path="/fr" component={Home}></Route>
						<Route exact path="/en" component={Home}></Route>
						<Route path="/fr/a-propos-de-nous" component={About}></Route>
						<Route path="/fr/contactez-nous" component={Contact}></Route>
						<Route
							exact
							path="/fr/services"
							render={() => <Redirect to="/fr/services/quantitative" />}
						></Route>
						<Route
							path="/fr/services/quantitative"
							component={Quantitative}
						></Route>
						<Route
							path="/fr/services/qualitative"
							component={Qualitative}
						></Route>
						<Route path="/fr/services/online" component={Online}></Route>
						<Route
							path="/fr/services/logistique"
							component={Logistics}
						></Route>
						{/* <Route exact path="/" component={Home}></Route> */}
						<Route path="/en/about-us" component={About}></Route>
						<Route path="/en/contact-us" component={Contact}></Route>
						<Route
							exact
							path="/en/services"
							render={() => <Redirect to="/en/services/quantitative" />}
						></Route>
						<Route
							path="/en/services/quantitative"
							component={Quantitative}
						></Route>
						<Route
							path="/en/services/qualitative"
							component={Qualitative}
						></Route>
						<Route path="/en/services/online" component={Online}></Route>
						<Route
							path="/en/services/logistics"
							component={Logistics}
						></Route>
						<Route
							exact
							path={'/fr/' + fr.routes.projects}
							render={props => <Landing {...props} filter={0} />}
						></Route>
						<Route
							exact
							path={`/fr/${fr.routes.projects}/${fr.routes.quantitative}`}
							render={props => <Landing {...props} filter={1} />}
						></Route>
						<Route
							exact
							path={`/fr/${fr.routes.projects}/${fr.routes.qualitative}`}
							render={props => <Landing {...props} filter={2} />}
						></Route>
						<Route
							exact
							path={`/fr/${fr.routes.projects}/${fr.routes.online}`}
							render={props => <Landing {...props} filter={3} />}
						></Route>
						<Route
							exact
							path={`/fr/${fr.routes.projects}/${fr.routes.logistics}`}
							render={props => <Landing {...props} filter={4} />}
						></Route>

						<Route
							exact
							path={'/en/' + en.routes.projects}
							render={props => <Landing {...props} filter={0} />}
						></Route>
						<Route
							exact
							path={`/en/${en.routes.projects}/${en.routes.quantitative}`}
							render={props => <Landing {...props} filter={1} />}
						></Route>
						<Route
							exact
							path={`/en/${en.routes.projects}/${en.routes.qualitative}`}
							render={props => <Landing {...props} filter={2} />}
						></Route>
						<Route
							exact
							path={`/en/${en.routes.projects}/${en.routes.online}`}
							render={props => <Landing {...props} filter={3} />}
						></Route>
						<Route
							exact
							path={`/en/${en.routes.projects}/${en.routes.logistics}`}
							render={props => <Landing {...props} filter={4} />}
						></Route>
						{projectsFR}
						{projectsEN}
						<Route render={NoMatch} />
					</Switch>
					<Footer lang={this.state.lang}></Footer>
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
