import React, { Component } from 'react';
import '../../styles/Home/Clients.scss';

export default class Clients extends Component {
	render() {
		return (
			<div className="clients-container">
				<div className="clients">
					<div className="client-item">
						<img
							src={require('../../images/home/clients/KantarTNS.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/BrainValue.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							id="Unilever"
							src={require('../../images/home/clients/Unilever.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/PernodRicard.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/Ipsos.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/PierreFabre.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/PSA.png')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/DiagnosticComm.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/Ubisoft.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/BVA.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/SGMAP.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/LOreal.png')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/FDJ.png')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/OpinionWay.jpg')}
							alt=""
						/>
					</div>
					<div className="client-item">
						<img
							src={require('../../images/home/clients/Ifop.jpg')}
							alt=""
						/>
					</div>
				</div>
			</div>
		);
	}
}
