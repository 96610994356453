import React, { Component } from 'react';
import '../../../styles/About/Info.scss';
import languageCheck from '../../HOC/languageCheck';
import YouTubeVideo from '../../Home/YouTubeVideo';

class Info extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	getTypeTitle = type => {
		switch (type) {
			case 1:
				return this.props.lang.services.quantitative.title;
			case 2:
				return this.props.lang.services.qualitative.title;
			case 3:
				return this.props.lang.services.online.title;
			case 4:
				return this.props.lang.services.logistics.title;

			default:
				break;
		}
	};

	render() {
		const lang = this.props.lang;
		const split = this.props.location.pathname.split('/');
		split.shift();
		split.shift();
		let [section, page, project] = split;
		const proj = lang.projects.projects.find(p => p.route === project);
		
		return (
			<div className="info">
				<div className="info-img-container">
					<img
						className="info-bg-image"
						src={require('../../../images/shared/studies/' + proj.image)}
						alt=""
					/>
				</div>
				<div className="info-container">
					<h2 className="project-type">{this.getTypeTitle(proj.type)}</h2>
					<h1 className="project-title">{proj.title}</h1>
					<div className="info-bar"></div>
					<p className="project-description">{proj.description}</p>
					<div className="project-explanation-grid">
						<div className="project-explanation">
							<h3 className="project-sub-title">{proj.brief.title}</h3>
							<p className="project-sub-description" dangerouslySetInnerHTML={{__html: proj.brief.info}}></p>
						</div>
						<div className="project-explanation">
							<h3 className="project-sub-title">{proj.method.title}</h3>
							<p className="project-sub-description" dangerouslySetInnerHTML={{__html: proj.method.info}}></p>
						</div>
						<div className="project-explanation">
							<h3 className="project-sub-title">{proj.results.title}</h3>
							<p className="project-sub-description" dangerouslySetInnerHTML={{__html: (typeof proj.results.link == "undefined" ? proj.results.info : proj.results.info.replace('__LINK__', require('../../../images/shared/studies/' + proj.results.link)))}}></p>
							{typeof proj.results.youtube != "undefined" ? (<YouTubeVideo link={proj.results.youtube}></YouTubeVideo>) : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default languageCheck(Info);
