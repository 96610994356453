import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import '../../styles/404/NoMatch.scss';
import languageCheck from '../HOC/languageCheck';

class NoMatch extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		const lang = this.props.lang;
		return (
			<div className="no-match">
				<Helmet>
					<title>404</title>
					<meta name="prerender-status-code" content="404" />
					<meta name="description" content="404" />
					<meta
						property="og:url"
						content={'https://hdquali.com/404.html'}
					/>
					<meta
						property="og:locale"
						content={lang.lang === 'en' ? 'en_US' : 'fr_FR'}
					/>
					<meta property="og:title" content="404" />
					<meta property="og:description" content="404" />
					<meta property="og:site_name" content="HDQuali" />
				</Helmet>
				<h1 className="no-match-title">404 - Page Not Found</h1>
			</div>
		);
	}
}

export default languageCheck(NoMatch);
