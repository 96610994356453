import React, { Component } from 'react';
import '../../styles/About/Info.scss';

export default class Info extends Component {
	render() {
		const paragraphs = this.props.text.split('\n').map((par, i) => (
			<p key={'about-' + i} className="info-text" dangerouslySetInnerHTML={{__html: par}}></p>
		));
		return (
			<div className="info">
				<div className="info-img-container">
					<img
						className="info-bg-image"
						src={require('../../images/shared/desk/HDQuali-about.jpg')}
						alt=""
					/>
				</div>
				<div className="info-container">
					<h1 className="info-title">{this.props.title}</h1>
					<div className="info-bar"></div>
					{paragraphs}
				</div>
			</div>
		);
	}
}
