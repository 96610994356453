import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Home/Services.scss';

export default class ServicesGrid extends Component {
	render() {
		const {
			qualitative,
			quantitative,
			online,
			logistics,
			quantitativeLink,
			qualitativeLink,
			onlineLink,
			logisticsLink
		} = this.props;
		const qualitativeTitle = qualitative.title.split(' ');
		const quantitativeTitle = quantitative.title.split(' ');
		const onlineTitle = online.title.split(' ');
		const logisticsTitle = logistics.title.split(' ');
		return (
			<div className="services-container">
				<div className="services-grid">
					<div
						id="photo-quantitative"
						className="service-item photo"
					></div>
					<div className="service-item info-container">
						<div className="service-info">
							<div className="info-bar-container">
								<div className="info-bar"></div>
							</div>
							<div className="info-data-container">
								<h2 className="info-title">
									{quantitativeTitle[0]}{' '}
									<span>
										{quantitativeTitle[1] ? quantitativeTitle[1] : ''}
									</span>
								</h2>
								<p>{quantitative.text}</p>
								<Link to={quantitativeLink} className="info-btn">
									{quantitative.button}
								</Link>
							</div>
						</div>
					</div>
					<div className="service-item info-container">
						<div className="service-info">
							<div className="info-bar-container">
								<div className="info-bar"></div>
							</div>
							<div className="info-data-container">
								<h2 className="info-title">
									{qualitativeTitle[0]}{' '}
									<span>
										{qualitativeTitle[1] ? qualitativeTitle[1] : ''}
									</span>
								</h2>
								<p>{qualitative.text}</p>
								<Link to={qualitativeLink} className="info-btn">
									{qualitative.button}
								</Link>
							</div>
						</div>
					</div>
					<div id="photo-qualitative" className="service-item photo"></div>
					<div id="photo-online" className="service-item photo"></div>
					<div className="service-item info-container">
						<div className="service-info">
							<div className="info-bar-container">
								<div className="info-bar"></div>
							</div>
							<div className="info-data-container">
								<h2 className="info-title">
									{onlineTitle[0]}{' '}
									<span>{onlineTitle[1] ? onlineTitle[1] : ''}</span>
								</h2>
								<p>{online.text}</p>
								<Link to={onlineLink} className="info-btn">
									{online.button}
								</Link>
							</div>
						</div>
					</div>
					<div className="service-item info-container">
						<div className="service-info">
							<div className="info-bar-container">
								<div className="info-bar"></div>
							</div>
							<div className="info-data-container">
								<h2 className="info-title">
									{logisticsTitle[0]}{' '}
									<span>
										{logisticsTitle[1] ? logisticsTitle[1] : ''}
									</span>
								</h2>
								<p>{logistics.text}</p>
								<Link to={logisticsLink} className="info-btn">
									{logistics.button}
								</Link>
							</div>
						</div>
					</div>
					<div id="photo-logistique" className="service-item photo"></div>
				</div>
			</div>
		);
	}
}
