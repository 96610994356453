import React, { Component } from 'react';

let offset = 200;

export default class YouTubeVideo extends Component {
	state = {
		showDrop: false,
		width: window.innerWidth - offset > 1170 ? 1170 : window.innerWidth * 0.8,
		height:
			window.innerWidth * 0.8 > 1242
				? 1242 / 1.77
				: (window.innerWidth * 0.8) / 1.77
	};

	componentDidMount() {
		if (window.innerWidth < 1000) offset = 100;
		if (window.innerWidth < 500) offset = 50;
		window.addEventListener('resize', this.updateDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		if (window.innerWidth >= 1000) offset = 200;
		if (window.innerWidth < 1000) offset = 100;
		if (window.innerWidth < 500) offset = 50;
		if (window.innerWidth - offset > 1242)
			this.setState({
				width: 1242,
				height: 1242 / 1.77
			});
		else
			this.setState({
				width: window.innerWidth * 0.8,
				height: (window.innerWidth * 0.8) / 1.77
			});
	};

	render() {
		return (
			<div class="youtube-video-container">
				<iframe
					width="100%"
					height="100%"
					style={{ marginBottom: '7rem' }}
					src={this.props.link}
					frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</div>
		);
	}
}
