export function makeServicesLink(lang, services, route) {
	return '/' + lang + '/' + services + '/' + route + '/';
}

export function makeLink(lang, route) {
	return '/' + lang + '/' + route + '/';
}

export function makeProjectLink(lang, route, filter, project) {
	return '/' + lang + '/' + route + '/' + filter + '/' + project + '/';
}

export function makeFilterLink(lang, route, filter) {
	return '/' + lang + '/' + route + '/' + filter;
}
