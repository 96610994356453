import React, { Component } from 'react';
import '../../styles/Home/Jumbotron.scss';

export default class Jumbotron extends Component {
	render() {
		return (
			<div className="jumbotron">
				<div className="jumbotron-text-background">
					<div className="jumbotron-text-container">
						<h1>
							<b>
								{this.props.title.toUpperCase()}
							</b>
						</h1>
					</div>
				</div>
			</div>
		);
	}
}
