import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../images/shared/HDQuali-logo-web-blue.png';
import '../../styles/Shared/Footer.scss';
import languageCheck from '../HOC/languageCheck';

class Footer extends Component {
	state = {
		facebook: false,
		linkedin: false
	};
	render() {
		return (
			<footer className="footer">
				<div className="footer-center">
					<div id="footer-logo" className="footer-piece">
						<Link to={'/' + this.props.lang.lang}>
							<img alt="HDQuali" src={logo}></img>
						</Link>
					</div>
					<div id="footer-contact" className="footer-piece">
						<p>
							{this.props.lang.contact.shared.address[0]}
							<span>{this.props.lang.contact.shared.address[1]}</span>
							<span>{this.props.lang.contact.shared.address[2]}</span>
						</p>
					</div>
					<div id="footer-social" className="footer-piece">
						<div className="social-filler"></div>
						<div className="social-container">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.facebook.com/hdquali/"
							>
								<span
									className="fa-stack fa-2x social-icon facebook"
								>
									<i className="fas fa-circle fa-stack-2x bg-circle-blue"></i>
									<i className="fab fa-facebook-f fa-stack-1x fa-inverse facebook"></i>
								</span>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.linkedin.com/company/hdquali/"
							>
								<span className="fa-stack fa-2x social-icon">
									<i
										className={
											this.state.linkedin
												? 'fas fa-circle fa-stack-2x bg-circle-red'
												: 'fas fa-circle fa-stack-2x bg-circle-blue'
										}
									></i>
									<i
										className="fab fa-linkedin-in fa-stack-1x fa-inverse linkedin"
										onMouseEnter={() =>
											this.setState({ linkedin: true })
										}
										onMouseLeave={() =>
											this.setState({ linkedin: false })
										}
									></i>
								</span>
							</a>
						</div>
						<div id="footer-copyright" className="footer-piece">
							<h4>2023 HDQuali</h4>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default withRouter(languageCheck(Footer));
