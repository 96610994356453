import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../../styles/Projects/Projects.scss';

export default class Projects extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	getTypeTitle = type => {
		switch (type) {
			case 1:
				return this.props.services.quantitative.title;
			case 2:
				return this.props.services.qualitative.title;
			case 3:
				return this.props.services.online.title;
			case 4:
				return this.props.services.logistics.title;

			default:
				break;
		}
	};

	getTypeRoute = type => {
		switch (type) {
			case 1:
				return this.props.lang.routes.quantitative;
			case 2:
				return this.props.lang.routes.qualitative;
			case 3:
				return this.props.lang.routes.online;
			case 4:
				return this.props.lang.routes.logistics;

			default:
				break;
		}
	};

	render() {
		const projects = this.props.projects
			.filter(p => {
				if (!this.props.filter) return p;
				else return p.type === this.props.filter;
			})
			.slice(0, this.props.max)
			.map(p => {
				const route = `/${this.props.lang.lang}/${
					this.props.lang.routes.projects
				}/${this.getTypeRoute(p.type)}/${p.route}/`;
				return (
					<Link
						key={route}
						to={route}
						className="project-container"
						style={{
							backgroundImage:
								'url(' +
								require(`../../../images/shared/studies/${p.imagemini}`) +
								')'
						}}
					>
						<div className="project-filler"></div>
						<div className="project-data">
							<h3 className="project-type">
								{this.getTypeTitle(p.type)}
							</h3>
							<h2 className="project-title">{p.title}</h2>
							<p className="project-description">{p.description}</p>
						</div>
					</Link>
				);
			});

		return (
			<div className={this.props.sub ? 'projects projects-sub' : 'projects'}>
				{/* <i className="fas fa-construction construction"></i> */}
				{/* <p>{this.props.construction}</p>
				<h1 className="studies-title">{this.props.clients}</h1>
				<div className="studies-bar"></div> */}
				{/* <p>{this.props.construction}</p> */}
				<div className="projects-grid">{projects}</div>
			</div>
		);
	}
}
