import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../images/shared/HDQuali-logo-web-blue.png';
import '../../styles/Shared/NavBar.scss';
import languageCheck from '../HOC/languageCheck';

class NavBar extends Component {
	state = {
		showDrop: false,
		showSub: false,
		width: 992,
		serviceHover: false
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		const split = this.props.location.pathname.split('/');
		split.shift();
		let lang = '';
		if (!split[0]) lang = 'fr';
		else if (split[0] === 'fr') lang = 'fr';
		else if (split[0] === 'en') lang = 'en';
		if (split[0] !== this.props.lang.lang) this.props.changeLang(lang);
		if (window.innerWidth !== this.state.width)
			this.setState({ width: window.innerWidth });
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ width: window.innerWidth });
		if (window.innerWidth > 991)
			this.setState({ showDrop: false, showSub: false });
	};

	showDrop = () => {
		this.setState({ showDrop: !this.state.showDrop });
	};

	showSub = () => {
		if (this.state.width < 991)
			this.setState({ showSub: !this.state.showSub });
	};

	render() {
		const split = this.props.location.pathname.split('/');
		split.shift();
		split.shift();
		let [section, page] = split;
		const lang = this.props.lang;
		const subMenu = [
			<li
				key="quantitative"
				className={
					section === lang.routes.services &&
					page === lang.routes.quantitative
						? 'sub-li active'
						: 'sub-li'
				}
			>
				<Link
					onClick={() => {
						this.setState({
							showDrop: false,
							showSub: false,
							serviceHover: false
						});
					}}
					className="sub-link"
					to={`/${lang.lang}/${lang.routes.services}/${lang.routes.quantitative}/`}
				>
					<div className="sub-selection-bar"></div>
					<h2>{lang.navbar.quantitative.toUpperCase()}</h2>
					<div className="filler-bar"></div>
				</Link>
			</li>,
			<li
				key="qualitative"
				className={
					section === lang.routes.services &&
					page === lang.routes.qualitative
						? 'sub-li active'
						: 'sub-li'
				}
			>
				<Link
					onClick={() => {
						this.setState({
							showDrop: false,
							showSub: false,
							serviceHover: false
						});
					}}
					className="sub-link"
					to={`/${lang.lang}/${lang.routes.services}/${lang.routes.qualitative}/`}
				>
					<div className="sub-selection-bar"></div>
					<h2>{lang.navbar.qualitative.toUpperCase()}</h2>
					<div className="filler-bar"></div>
				</Link>
			</li>,
			<li
				key="online"
				className={
					section === lang.routes.services && page === lang.routes.online
						? 'sub-li active'
						: 'sub-li'
				}
			>
				<Link
					onClick={() => {
						this.setState({
							showDrop: false,
							showSub: false,
							serviceHover: false
						});
					}}
					className="sub-link"
					to={`/${lang.lang}/${lang.routes.services}/${lang.routes.online}/`}
				>
					<div className="sub-selection-bar"></div>
					<h2>{lang.navbar.online.toUpperCase()}</h2>
					<div className="filler-bar"></div>
				</Link>
			</li>,
			<li
				key="logistique"
				className={
					section === lang.routes.services &&
					page === lang.routes.logistics
						? 'sub-li active'
						: 'sub-li'
				}
			>
				<Link
					onClick={() => {
						this.setState({
							showDrop: false,
							showSub: false,
							serviceHover: false
						});
					}}
					className="sub-link"
					to={`/${lang.lang}/${lang.routes.services}/${lang.routes.logistics}/`}
				>
					<div className="sub-selection-bar"></div>
					<h2>{lang.navbar.logistics.toUpperCase()}</h2>
					<div className="filler-bar"></div>
				</Link>
			</li>
		];

		return (
			<header>
				<div className="logo">
					<Link
						onClick={() => {
							this.setState({ showDrop: false, showSub: false });
						}}
						to={'/' + lang.lang}
					>
						<img alt="HDQuali" src={logo}></img>
					</Link>
				</div>
				<nav
					className={this.state.showDrop ? 'nav-active' : 'nav-inactive'}
				>
					<ul className="link-list">
						<li
							className={
								section === lang.routes.about
									? 'main-li active'
									: 'main-li'
							}
						>
							<Link
								onClick={() => {
									this.setState({ showDrop: false, showSub: false });
								}}
								className="main-li-a"
								to={`/${lang.lang}/${lang.routes.about}/`}
							>
								<div className="selection-bar"></div>
								<h2>{lang.navbar.about.toUpperCase()}</h2>
								<div className="filler-bar"></div>
							</Link>
						</li>
						<li
							onMouseEnter={() => this.setState({ serviceHover: true })}
							onMouseLeave={() => this.setState({ serviceHover: false })}
							className={
								section === lang.routes.services
									? 'main-li active'
									: 'main-li'
							}
							onClick={this.showSub}
						>
							{this.state.width > 991 ? (
								<div className="main-li-a">
									<div className="selection-bar"></div>
									<h2>{lang.navbar.services.toUpperCase()}</h2>
									<div className="filler-bar"></div>
								</div>
							) : (
								<div className="drop-down-menu">
									<div className="selection-bar"></div>
									<div className="container">
										<div></div>
										<h2 className="services-drop-down">
											{lang.navbar.services.toUpperCase()}
										</h2>
										<div>
											{this.state.showSub ? (
												<i
													className="fa fa-angle-up"
													aria-hidden="true"
												></i>
											) : (
												<i
													className="fa fa-angle-down"
													aria-hidden="true"
												></i>
											)}
										</div>
									</div>
									<div className="filler-bar"></div>
								</div>
							)}

							<ul
								className={
									this.state.serviceHover
										? 'desktop-sub-menu show'
										: 'desktop-sub-menu'
								}
							>
								{subMenu}
							</ul>
						</li>
						{this.state.showSub ? (
							<div className="sub-menu">{subMenu}</div>
						) : (
							''
						)}
						<li className="main-li">
							<Link
								className="main-li-a"
								onClick={() => {
									this.setState({ showDrop: false, showSub: false });
								}}
								to={`/${lang.lang}/${lang.routes.projects}/`}
							>
								<div className="selection-bar"></div>
								<h2>{lang.navbar.projects.toUpperCase()}</h2>
								<div className="filler-bar"></div>
							</Link>
						</li>
						<li
							className={
								section === lang.routes.contact
									? 'main-li active'
									: 'main-li'
							}
						>
							<Link
								onClick={() => {
									this.setState({ showDrop: false, showSub: false });
								}}
								className="main-li-a"
								to={`/${lang.lang}/${lang.routes.contact}/`}
							>
								<div className="selection-bar"></div>
								<h2>{lang.navbar.contact.toUpperCase()}</h2>
								<div className="filler-bar"></div>
							</Link>
						</li>
						<li className="main-li li-languages">
							<Link
								id="fr"
								className={
									lang.lang === 'fr'
										? 'li-lang active-lang'
										: 'li-lang'
								}
								onClick={() => this.props.changeLangRoute('fr')}
								to={this.props.setLangRoute('fr', split)}
							>
								FR
							</Link>
							/
							<Link
								id="en"
								className={
									lang.lang === 'en'
										? 'li-lang active-lang'
										: 'li-lang'
								}
								onClick={() => this.props.changeLangRoute('en')}
								to={this.props.setLangRoute('en', split)}
							>
								EN
							</Link>
						</li>
					</ul>
				</nav>
				<div className="menu-toggle" onClick={this.showDrop}>
					<i className="fa fa-bars" aria-hidden="true"></i>
				</div>
			</header>
		);
	}
}

export default withRouter(languageCheck(NavBar));
