import React, { Component } from 'react';
import languageCheck from '../HOC/languageCheck';
import ContactSection from '../Shared/ContactSection';
import Head from '../Shared/Head';
import { makeLink, makeServicesLink } from '../Shared/utils';
import Clients from './Clients';
import InfoButton from './InfoButton';
import Jumbotron from './Jumbotron';
import Services from './Services';
import YouTubeVideo from './YouTubeVideo';

class Home extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		const { lang } = this.props;
		return (
			<div className="home">
				<Head
					lang={lang.lang}
					title={lang.home.meta.title}
					description={lang.home.meta.description}
					route={'/' + lang.lang}
					img={require('../../images/shared/desk/Header.jpg')}
				></Head>
				<Jumbotron title={lang.home[1].title}></Jumbotron>
				<InfoButton
					title={lang.home[2].title}
					button={lang.home[2].button}
					text={lang.home[2].text}
					link={makeLink(lang.lang, lang.routes.about)}
				></InfoButton>
				<YouTubeVideo link={lang.home.youtube}></YouTubeVideo>
				<Services
					quantitative={lang.home[3].quantitative}
					qualitative={lang.home[3].qualitative}
					online={lang.home[3].online}
					logistics={lang.home[3].logistics}
					quantitativeLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.quantitative
					)}
					qualitativeLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.qualitative
					)}
					onlineLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.online
					)}
					logisticsLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.online
					)}
				></Services>
				<InfoButton
					title={lang.home[4].title}
					button={lang.home[4].button}
					text={lang.home[4].text}
					link={makeLink(lang.lang, lang.routes.projects)}
				></InfoButton>
				<Clients></Clients>
				<ContactSection
					title={lang.contact.shared.title}
					button={lang.contact.shared.button}
					text={lang.contact.shared.text}
					contactLink={makeLink(lang.lang, lang.routes.contact)}
				></ContactSection>
			</div>
		);
	}
}

export default languageCheck(Home);
