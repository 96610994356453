import React, { Component } from 'react';
import '../../styles/About/Employees.scss';

export default class Employees extends Component {
	render() {
		const employees = this.props.employees.map((e, i) => {
			const [first, last] = e.name.split(" ");
			return (
				<div key={"employee-" + i} className="employees-item">
					<div className="employees-name-container">
						<h3 className="employees-name">
							{first}<span>{last}</span>
						</h3>
					</div>
					<div className="employees-title-container">
						<h4 className="employees-title">{e.title}</h4>
					</div>
				</div>
			)
		});
		return (
			<div className="employees">
				<div className="employees-grid">
					{employees}
				</div>
			</div>
		);
	}
}
