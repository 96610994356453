import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../../styles/Projects/Project.scss';
import languageCheck from '../../HOC/languageCheck';
import Contact from '../../Shared/ContactSection';
import Head from '../../Shared/Head';
import { makeLink, makeProjectLink } from '../../Shared/utils';
import Projects from '../Shared/Projects';
import Info from './Info';

class Project extends Component {
	render() {
		const lang = this.props.lang;
		const split = this.props.location.pathname.split('/');
		split.shift();
		split.shift();
		let [section, page, project] = split;
		const proj = lang.projects.projects.find(p => p.route === project);
		return (
			<div className="project">
				<Info location={this.props.location}></Info>
				<div className="projects-back-container">
					<Head
						description={proj.meta.description}
						lang={lang.lang}
						route={makeProjectLink(
							lang.lang,
							lang.routes.projects,
							page,
							project
						)}
						title={proj.meta.title}
						img={require('../../../images/shared/studies/' + proj.image)}
					></Head>
					<div className="projects-back">
						<Link
							className="projects-back-link"
							to={`/${lang.lang}/${lang.routes.projects}/`}
						>
							{'<< '}{lang.projects.return}
						</Link>
					</div>
				</div>
				<div className="other-projects-container">
					<h1 className="other-projects-title">{lang.projects.other}</h1>
					<div className="info-bar"></div>
				</div>
				<Projects
					sub={true}
					filter={proj.type}
					lang={lang}
					max={3}
					projects={lang.projects.projects}
					services={lang.services}
				></Projects>
				<Contact
					title={lang.contact.shared.title}
					button={lang.contact.shared.button}
					text={lang.contact.shared.text}
					contactLink={makeLink(lang.lang, lang.routes.contact)}
				></Contact>
			</div>
		);
	}
}

export default languageCheck(Project);
