import React, { Component } from 'react';
import languageCheck from '../HOC/languageCheck';
import ContactSection from '../Shared/ContactSection';
import Head from '../Shared/Head';
import Services from '../Shared/Services';
import { makeLink, makeServicesLink } from '../Shared/utils';
import Employees from './Employees';
import Info from './Info';

class About extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		const lang = this.props.lang;
		return (
			<div>
				<Head
					lang={lang.lang}
					title={lang.about.meta.title}
					description={lang.about.meta.description}
					route={makeLink(lang.lang, lang.routes.about)}
					img={require('../../images/shared/desk/HDQuali-about.jpg')}
				></Head>
				<Info title={lang.about[1].title} text={lang.about[1].text}></Info>
				<Employees employees={lang.about[2].employees}></Employees>
				<Services
					quantitative={lang.services.quantitative}
					qualitative={lang.services.qualitative}
					online={lang.services.online}
					logistics={lang.services.logistics}
					quantitativeLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.quantitative
					)}
					qualitativeLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.qualitative
					)}
					onlineLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.online
					)}
					logisticsLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.online
					)}
				></Services>
				<ContactSection
					title={lang.contact.shared.title}
					button={lang.contact.shared.button}
					text={lang.contact.shared.text}
					contactLink={makeLink(lang.lang, lang.routes.contact)}
				></ContactSection>
			</div>
		);
	}
}

export default languageCheck(About);
