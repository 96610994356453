import React, { Component } from 'react';
import languageCheck from '../HOC/languageCheck';
import Head from '../Shared/Head';
import { makeLink } from '../Shared/utils';
import Form from './Form';
import Jumbotron from './Jumbotron';

class Contact extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		const lang = this.props.lang;
		return (
			<div>
				<Head
					lang={lang.lang}
					title={lang.contact.meta.title}
					description={lang.contact.meta.description}
					route={makeLink(lang.lang, lang.routes.contact)}
					img={require('../../images/shared/desk/Contact.jpg')}
				></Head>
				<Jumbotron
					title={lang.contact.title}
					contact={lang.contact}
				></Jumbotron>
				<Form
					title={lang.contact.shared.title}
					text={lang.contact.shared.text}
					inputs={lang.contact.form}
					button={lang.contact.button}
					sent={lang.contact.sent}
					missing={lang.contact.missing}
					error={lang.contact.error}
				></Form>
			</div>
		);
	}
}

export default languageCheck(Contact);
