import axios from 'axios';
import * as EmailValidator from 'email-validator';
import React, { Component } from 'react';
import '../../styles/Contact/Form.scss';

export default class Form extends Component {
	state = {
		name: '',
		company: '',
		email: '',
		subject: '',
		message: '',
		userMessage: '',
		error: {
			name: false,
			email: false,
			subject: false,
			message: false,
			server: false,
			sent: false
		}
	};

	nameChange = e => {
		const name = e.target.value;
		this.setState({ error: { ...this.state.error, name: false } });
		this.setState({ name });
	};

	companyChange = e => {
		const company = e.target.value;
		this.setState({ company });
	};

	emailChange = e => {
		const email = e.target.value;
		this.setState({ error: { ...this.state.error, email: false } });
		this.setState({ email });
	};

	subjectChange = e => {
		const subject = e.target.value;
		this.setState({ error: { ...this.state.error, subject: false } });
		this.setState({ subject });
	};

	messageChange = e => {
		const message = e.target.value;
		this.setState({ error: { ...this.state.error, message: false } });
		this.setState({ message });
	};

	sendContact = () => {
		let name = false;
		let email = false;
		let message = false;
		let subject = false;

		if (!this.state.name) {
			name = true;
		}
		if (!this.state.subject) {
			subject = true;
		}
		if (!this.state.message) {
			message = true;
		}
		if (!this.state.email || !EmailValidator.validate(this.state.email)) {
			email = true;
		}
		this.setState({
			userMessage:
				name || subject || message || email ? this.props.missing : '',
			error: {
				...this.state.error,
				email,
				name,
				message,
				subject,
				server: false,
				sent: false
			}
		});

		// console.log('state', JSON.stringify(this.state));

		if (!name && !email && !subject && !message)
			axios
				.post('https://api.hdquali.com/api/contact/send', {
					name: this.state.name,
					company: this.state.company,
					email: this.state.email,
					subject: this.state.subject,
					message: this.state.message
				})
				.then(response => {
					// console.log('response', response);
					if (response.status === 200)
						this.setState({
							userMessage: this.props.sent,
							error: { ...this.state.error, sent: true }
						});
					else
						this.setState({
							userMessage: this.props.error,
							error: { ...this.state.error, server: true }
						});
				})
				.catch(error => {
					// console.log('error', JSON.stringify(error));
					this.setState({
						userMessage: this.props.error,
						error: { ...this.state.error, server: true }
					});
				});
	};

	render() {
		return (
			<div className="form">
				<div className="form-title-container">
					<h2 className="font-bold">{this.props.title}</h2>
					<h3 className="font-normal">{this.props.text}</h3>
				</div>
				<div className="form-container-grid">
					<input
						name="name"
						className={
							this.state.error.name
								? 'font-normal form-item input-missing'
								: 'font-normal form-item'
						}
						value={this.state.name}
						type="text"
						placeholder={this.props.inputs.name}
						onChange={this.nameChange}
					/>
					<input
						name="company"
						className="font-normal form-item"
						type="text"
						placeholder={this.props.inputs.company}
						onChange={this.companyChange}
					/>
					<input
						name="email"
						className={
							this.state.error.email
								? 'font-normal form-item input-missing'
								: 'font-normal form-item'
						}
						type="text"
						placeholder={this.props.inputs.email}
						onChange={this.emailChange}
					/>
					<input
						name="subject"
						className={
							this.state.error.subject
								? 'font-normal form-item input-missing'
								: 'font-normal form-item'
						}
						type="text"
						placeholder={this.props.inputs.subject}
						onChange={this.subjectChange}
					/>
					<textarea
						name="message"
						id=""
						cols="30"
						rows="10"
						className={
							this.state.error.message
								? 'font-normal form-item input-missing'
								: 'font-normal form-item'
						}
						placeholder={this.props.inputs.message}
						onChange={this.messageChange}
					></textarea>
				</div>
				<div className="form-button-response-container">
					{this.state.error.sent ? (
						<h4>
							{this.props.sent[0]}
							<br /> {this.props.sent[1]}
						</h4>
					) : (
						<h4>
							{this.state.error.name ||
							this.state.error.email ||
							this.state.error.message ||
							this.state.error.subject ||
							this.state.error.server ||
							this.state.error.sent
								? this.state.userMessage
								: ''}
						</h4>
					)}
					{this.state.error.sent ? (
						''
					) : (
						<button
							className="quali-button form-button"
							onClick={this.sendContact}
						>
							{this.props.button}
						</button>
					)}
				</div>
			</div>
		);
	}
}
