import React, { Component } from 'react';
import languageCheck from '../../HOC/languageCheck';
import ContactSection from '../../Shared/ContactSection';
import Head from '../../Shared/Head';
import Services from '../../Shared/Services';
import { makeLink, makeServicesLink } from '../../Shared/utils';
import Info from './Info';

class Online extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		const lang = this.props.lang;
		return (
			<div>
				<Head
					lang={lang.lang}
					title={lang.online.meta.title}
					description={lang.online.meta.description}
					route={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.online
					)}
					img={require('../../../images/shared/desk/Online.jpg')}
				></Head>
				<Info
					title={lang.online.title}
					section1={lang.online[1]}
					section2={lang.online[2]}
					section3={lang.online[3]}
					section4={lang.online[4]}
					section4Link={
						'/' +
						lang.lang +
						'/' +
						lang.routes.services +
						'/' +
						lang.routes.logistics
					}
				></Info>
				<Services
					quantitative={lang.services.quantitative}
					qualitative={lang.services.qualitative}
					online={lang.services.online}
					logistics={lang.services.logistics}
					quantitativeLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.quantitative
					)}
					qualitativeLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.qualitative
					)}
					onlineLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.online
					)}
					logisticsLink={makeServicesLink(
						lang.lang,
						lang.routes.services,
						lang.routes.logistics
					)}
				></Services>
				<ContactSection
					title={lang.contact.shared.title}
					button={lang.contact.shared.button}
					text={lang.contact.shared.text}
					contactLink={makeLink(lang.lang, lang.routes.contact)}
				></ContactSection>
			</div>
		);
	}
}

export default languageCheck(Online);
