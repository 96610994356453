import React from 'react';
import en from '../../languages/english.json';
import fr from '../../languages/french.json';

const setLang = lang => {
	switch (lang) {
		case 'fr':
			return fr;
		case 'en':
			return en;

		default:
			return fr;
	}
};

const setLangRoute = (lang, split) => {
	let [section, page, project] = split;
	if (!section) return '/' + (lang === 'en' ? 'en' : 'fr');
	if (section === fr.routes.services || section === en.routes.services) {
		section = lang === 'en' ? en.routes.services : fr.routes.services;
		if (page === fr.routes.quantitative || page === en.routes.quantitative)
			page = lang === 'en' ? en.routes.quantitative : fr.routes.quantitative;
		else if (page === fr.routes.qualitative || page === en.routes.qualitative)
			page = lang === 'en' ? en.routes.qualitative : fr.routes.qualitative;
		else if (page === fr.routes.online || page === en.routes.online)
			page = lang === 'en' ? en.routes.online : fr.routes.online;
		else if (page === fr.routes.logistics || page === en.routes.logistics)
			page = lang === 'en' ? en.routes.logistics : fr.routes.logistics;
	} else if (
		section === fr.routes.projects ||
		section === en.routes.projects
	) {
		section = lang === 'en' ? en.routes.projects : fr.routes.projects;
		if (page === fr.routes.quantitative || page === en.routes.quantitative)
			page = lang === 'en' ? en.routes.quantitative : fr.routes.quantitative;
		else if (page === fr.routes.qualitative || page === en.routes.qualitative)
			page = lang === 'en' ? en.routes.qualitative : fr.routes.qualitative;
		else if (page === fr.routes.online || page === en.routes.online)
			page = lang === 'en' ? en.routes.online : fr.routes.online;
		else if (page === fr.routes.logistics || page === en.routes.logistics)
			page = lang === 'en' ? en.routes.logistics : fr.routes.logistics;
		for (let x = 0; x < fr.projects.projects.length; x++) {
			const pFR = fr.projects.projects[x].route;
			const pEN = en.projects.projects[x].route;
			if (project === pFR || project === pEN) {
				project = lang === 'en' ? pEN : pFR;
				break;
			}
		}
	} else {
		page = '';
		if (section === fr.routes.about || section === en.routes.about) {
			section = lang === 'en' ? en.routes.about : fr.routes.about;
		} else if (
			section === fr.routes.contact ||
			section === en.routes.contact
		) {
			section = lang === 'en' ? en.routes.contact : fr.routes.contact;
		}
	}
	const newLang = lang === 'en' ? 'en' : 'fr';
	let route =
		'/' +
		newLang +
		'/' +
		section +
		(page ? '/' + page : '') +
		(project ? '/' + project : '');

	return route;
};

const languageCheck = ChildComponent => {
	function ComposedComponent(props) {
		const split = props.location.pathname.split('/');
		split.shift();
		let [language] = split;
		language = props.lang ? props.lang : language;
		let lang = setLang(language);
		return (
			<ChildComponent {...props} lang={lang} setLangRoute={setLangRoute} />
		);
	}

	return ComposedComponent;
};

export default languageCheck;
