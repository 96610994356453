import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../../styles/Projects/Filters.scss';

export default class Filters extends Component {
	render() {
		return (
			<div className="filters">
				<div className="filters-container">
					<Link
						className={
							this.props.filter === 1
								? 'filter-item filter-item-selected'
								: 'filter-item'
						}
						to={`/${this.props.lang.lang}/${this.props.lang.routes.projects}/${this.props.quantitativeLink}`}
					>
						<div className="filter-icon quantitative"></div>
						<h3>{this.props.quantitative}</h3>
					</Link>
					<Link
						className={
							this.props.filter === 2
								? 'filter-item filter-item-selected'
								: 'filter-item'
						}
						to={`/${this.props.lang.lang}/${this.props.lang.routes.projects}/${this.props.qualitativeLink}`}
					>
						<div className="filter-icon qualitative"></div>
						<h3>{this.props.qualitative}</h3>
					</Link>
					<Link
						className={
							this.props.filter === 3
								? 'filter-item filter-item-selected'
								: 'filter-item'
						}
						to={`/${this.props.lang.lang}/${this.props.lang.routes.projects}/${this.props.onlineLink}`}
					>
						<div className="filter-icon online"></div>
						<h3>{this.props.online}</h3>
					</Link>
					<Link
						className={
							this.props.filter === 4
								? 'filter-item filter-item-selected'
								: 'filter-item'
						}
						to={`/${this.props.lang.lang}/${this.props.lang.routes.projects}/${this.props.logisticsLink}`}
					>
						<div className="filter-icon logistics"></div>
						<h3>{this.props.logistics}</h3>
					</Link>
				</div>
			</div>
		);
	}
}
