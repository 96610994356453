import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Shared/Services.scss';

export default class Services extends Component {
	render() {
		return (
			<div className="services">
				<div className="services-grid">
					<Link
						className="service-item-link"
						to={this.props.quantitativeLink}
					>
						<div className="service-item">
							<div className="service-title-container">
								<h3>{this.props.quantitative.title}</h3>
							</div>
							<div className="service-info-container">
								<p>{this.props.quantitative.text}</p>
							</div>
							<div className="service-icon-container service-icon quantitative"></div>
							<div className="service-button-container">
								<div className="service-button-link">
									{this.props.quantitative.button}
								</div>
							</div>
						</div>
					</Link>
					<Link
						className="service-item-link"
						to={this.props.qualitativeLink}
					>
						<div className="service-item">
							<div className="service-title-container">
								<h3>{this.props.qualitative.title}</h3>
							</div>
							<div className="service-info-container">
								<p>{this.props.qualitative.text}</p>
							</div>
							<div className="service-icon-container service-icon qualitative"></div>
							<div className="service-button-container">
								<div className="service-button-link">
									{this.props.qualitative.button}
								</div>
							</div>
						</div>
					</Link>
					<Link className="service-item-link" to={this.props.onlineLink}>
						<div className="service-item">
							<div className="service-title-container">
								<h3>{this.props.online.title}</h3>
							</div>
							<div className="service-info-container">
								<p>{this.props.online.text}</p>
							</div>
							<div className="service-icon-container service-icon online"></div>
							<div className="service-button-container">
								<div className="service-button-link">
									{this.props.online.button}
								</div>
							</div>
						</div>
					</Link>
					<Link
						className="service-item-link"
						to={this.props.logisticsLink}
					>
						<div className="service-item">
							<div className="service-title-container">
								<h3>{this.props.logistics.title}</h3>
							</div>
							<div className="service-info-container">
								<p>{this.props.logistics.text}</p>
							</div>
							<div className="service-icon-container service-icon logistics"></div>
							<div className="service-button-container">
								<div className="service-button-link">
									{this.props.logistics.button}
								</div>
							</div>
						</div>
					</Link>
				</div>
			</div>
		);
	}
}
