import React, { Component } from 'react';
import '../../styles/Contact/Jumbotron.scss';

export default class Jumbotron extends Component {
	state = {
		facebook: false,
		linkedin: false
	};
	render() {
		return (
			<div className="contact-jumbotron">
				<div className="contact-title-container">
					<h1 className="contact-title">{this.props.title}</h1>
					<div className="contact-bar"></div>
				</div>
				<div className="contact-info-container">
					<div className="contact-info">
						<p>
							{this.props.contact.shared.address[0]}
							<span>{this.props.contact.shared.address[1]}</span>
							<span>{this.props.contact.shared.address[2]}</span>
						</p>
						<h3 className="contact-email">contact@hdquali.com</h3>
						<div className="contact-social">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.facebook.com/hdquali/"
							>
								<span
									className="fa-stack fa-2x social-icon"
									onMouseEnter={() =>
										this.setState({ facebook: true })
									}
									onMouseLeave={() =>
										this.setState({ facebook: false })
									}
								>
									<i
										className={
											this.state.facebook
												? 'fas fa-circle fa-stack-2x bg-circle-red'
												: 'fas fa-circle fa-stack-2x bg-circle-blue'
										}
									></i>
									<i className="fab fa-facebook-f fa-stack-1x fa-inverse facebook"></i>
								</span>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.linkedin.com/company/hdquali/"
							>
								<span className="fa-stack fa-2x social-icon">
									<i
										className={
											this.state.linkedin
												? 'fas fa-circle fa-stack-2x bg-circle-red'
												: 'fas fa-circle fa-stack-2x bg-circle-blue'
										}
									></i>
									<i
										className="fab fa-linkedin-in fa-stack-1x fa-inverse linkedin"
										onMouseEnter={() =>
											this.setState({
												linkedin: true
											})
										}
										onMouseLeave={() =>
											this.setState({
												linkedin: false
											})
										}
									></i>
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
