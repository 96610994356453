import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Shared/ContactSection.scss';

export default class ContactSection extends Component {
	render() {
		return (
			<div className="contact-section">
				<div className="contact-container">
					<h2>{this.props.title}</h2>
					<h3>{this.props.text}</h3>
					<Link to={this.props.contactLink}>{this.props.button}</Link>
				</div>
			</div>
		);
	}
}
