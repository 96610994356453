import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Head extends Component {
	render() {
		const lang = this.props.lang;
		return (
			<Helmet htmlAttributes={{ lang: lang }}>
				<title>{this.props.title}</title>
				<meta name="description" content={this.props.description} />
				<meta
					property="og:url"
					content={'https://hdquali.com' + this.props.route}
				/>
				<meta
					property="og:locale"
					content={lang === 'en' ? 'en_US' : 'fr_FR'}
				/>
				<meta property="og:title" content={this.props.title} />
				<meta property="og:description" content={this.props.description} />
				<meta property="og:site_name" content="HDQuali" />
				<meta property="og:image" content={'https://hdquali.com' + this.props.img} />
			</Helmet>
		);
	}
}
