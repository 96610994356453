import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../../styles/About/Info.scss';

export default class Info extends Component {
	render() {
		return (
			<div className="info">
				<div className="info-img-container">
					<img
						className="info-bg-image"
						src={require('../../../images/shared/desk/Logistique.jpg')}
						alt=""
					/>
				</div>
				<div className="info-container">
					<img
						src={require('../../../images/shared/icons/Logistics-blue.png')}
						alt=""
					/>
					<h1 className="info-title">{this.props.title}</h1>
					<div className="info-bar"></div>
					<h2 className="info-sub-title">{this.props.section1.title}</h2>
					<p className="info-text">{this.props.section1.text[0]}</p>
					<p className="info-text">
						{this.props.section1.text[1]}{' '}
						<Link to={this.props.section1Link}>
							{this.props.section1.text[2]}
						</Link>
						<br />
						{this.props.section1.text[3]}
					</p>
					<ul className="info-list">
						<li>{this.props.section1.list[0]}</li>
						<li>{this.props.section1.list[1]}</li>
						<li>{this.props.section1.list[2]}</li>
						<li>{this.props.section1.list[3]}</li>
						<li>{this.props.section1.list[4]}</li>
					</ul>
					<div className="info-block">
						<p>{this.props.section2.text}</p>
					</div>
				</div>
			</div>
		);
	}
}
