import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../../styles/About/Info.scss';

export default class Info extends Component {
	render() {
		return (
			<div className="info">
				<div className="info-img-container">
					<img
						className="info-bg-image"
						src={require('../../../images/shared/desk/Quantitative.jpg')}
						alt=""
					/>
				</div>
				<div className="info-container">
					<img
						src={require('../../../images/shared/icons/Quantitative-blue.png')}
						alt=""
					/>
					<h1 className="info-title">{this.props.title}</h1>
					<div className="info-bar"></div>
					<h2 className="info-sub-title">{this.props.section1.title}</h2>
					<p className="info-text">{this.props.section1.text}</p>
					<h2 className="info-sub-title">{this.props.section2.title}</h2>
					<p className="info-text">{this.props.section2.text[0]}</p>
					<p className="info-text">{this.props.section2.text[1]}</p>
					<h2 className="info-sub-title">{this.props.section3.title}</h2>
					<p className="info-text">{this.props.section3.text[0]}</p>
					<p className="info-text">{this.props.section3.text[1]}</p>
					<h2 className="info-sub-title">{this.props.section4.title}</h2>
					<p className="info-text">{this.props.section4.text[0]}</p>
					<p className="info-text">
						{this.props.section4.text[1]}{' '}
						<Link to={this.props.section4Link}>
							{this.props.section4.text[2]}
						</Link>{' '}
						{this.props.section4.text[3]}
					</p>
					<p className="info-text">{this.props.section4.text[4]}</p>
					<p className="info-text">{this.props.section4.text[5]}</p>
				</div>
			</div>
		);
	}
}
