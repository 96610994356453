import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Home/InfoButton.scss';

export default class InfoButton extends Component {
	render() {
		return (
			<div className="info-button">
				<div className="info-button-container">
					<h2 className="title">{this.props.title.toUpperCase()}</h2>
					<div className="bar"></div>
					<p className="info">{this.props.text}</p>
					<Link to={this.props.link} className="see-more-btn">
						{this.props.button.toUpperCase()}
					</Link>
				</div>
			</div>
		);
	}
}
