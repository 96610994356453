import React, { Component } from 'react';
import '../../../styles/Projects/Landing.scss';
import languageCheck from '../../HOC/languageCheck';
import Clients from '../../Home/Clients';
import ContactSection from '../../Shared/ContactSection';
import Head from '../../Shared/Head';
import { makeFilterLink, makeLink } from '../../Shared/utils';
import Projects from '../Shared/Projects';
import Filters from './Filters';

class Landing extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	getMeta = filter => {
		switch (filter) {
			case 0:
				return this.props.lang.projects.meta.none;
			case 1:
				return this.props.lang.projects.meta.quantitative;
			case 2:
				return this.props.lang.projects.meta.qualitative;
			case 3:
				return this.props.lang.projects.meta.online;
			case 4:
				return this.props.lang.projects.meta.logistics;
			default:
				return this.props.lang.projects.meta.none;
		}
	};

	render() {
		const lang = this.props.lang;
		const meta = this.getMeta(this.props.filter);
		const split = this.props.location.pathname.split('/');
		split.shift();
		split.shift();
		let [section, page, project] = split;
		return (
			<div className="landing">
				<Head
					description={meta.description}
					lang={lang.lang}
					route={
						this.props.filter
							? makeFilterLink(lang.lang, lang.routes.projects, page)
							: makeLink(lang.lang, lang.routes.projects)
					}
					title={meta.title}
				></Head>
				<div className="studies-title-container">
					<h1 className="studies-title">{lang.projects.title}</h1>
					<div className="studies-bar"></div>
				</div>
				<Filters
					filter={this.props.filter}
					lang={lang}
					logistics={lang.logistics.title}
					logisticsLink={lang.routes.logistics}
					online={lang.online.title}
					onlineLink={lang.routes.online}
					qualitative={lang.qualitative.title}
					qualitativeLink={lang.routes.qualitative}
					quantitative={lang.quantitative.title}
					quantitativeLink={lang.routes.quantitative}
				></Filters>
				<Projects
					sub={false}
					clients={lang.projects.shared.clients}
					construction={lang.projects.construction}
					filter={this.props.filter}
					lang={lang}
					max={99}
					projects={lang.projects.projects}
					services={lang.services}
				></Projects>
				<ContactSection
					button={lang.projects.shared.button}
					contactLink={makeLink(lang.lang, lang.routes.contact)}
					text={lang.projects.shared.text}
					title={lang.projects.shared.title}
				></ContactSection>
			</div>
		);
	}
}

export default languageCheck(Landing);
